<template>
  <div class="page-wraper">

    <!-- Preloader -->
    <div id="preloader">
      <div class="spinner"></div>
    </div>
    <!-- Preloader end-->

    <!-- Page Content -->
    <div class="page-content">

      <!-- Banner -->
      <div class="banner-wrapper shape-1">
        <div class="container inner-wrapper">
          <h2 class="dz-title">Create an Account</h2>
          <p class="mb-0">Please fill registration form below</p>
          <span v-if="error" style="color: red;">{{ error }}</span>
          <span v-if="success" style="color: darkseagreen;">{{ success }}</span>
        </div>
      </div>
      <!-- Banner End -->

      <div class="container">
        <div class="account-area">
          <form>
            <div class="input-group">
              <input type="text" placeholder="First Name" class="form-control" name="first_name" v-model="first_name">
            </div>
            <div class="input-group">
              <input type="text" placeholder="Last Name" class="form-control" name="last_name" v-model="last_name">
            </div>
            <div class="input-group">
              <input type="email" placeholder="Enter Email" class="form-control" v-model="email">
            </div>
            <!--            <span v-if="isEmailInputTouched && !isEmailValid" style="color: red;">Please enter a valid Email.</span>-->
            <div class="input-group">
              <input type="text" placeholder="Phone Number" class="form-control"
                     v-model="phone_number" id="phone_number" @input="isInputTouched = true">
            </div>
            <span v-if="isInputTouched && !isNumberValid" style="color: red;">Please enter a valid number.</span>
            <div class="input-group">
              <input type="password" placeholder="Password" class="form-control be-0" name="password"
                     v-model="password">
              <span class="input-group-text show-pass">
                  <i class="fa fa-eye-slash"></i>
                  <i class="fa fa-eye"></i>
              </span>
            </div>
            <div class="input-group">
              <input type="password" placeholder="Confirm Password" class="form-control be-0"
                     name="password_confirmation" v-model="password_confirmation">
              <span class="input-group-text show-pass">
                  <i class="fa fa-eye-slash"></i>
                  <i class="fa fa-eye"></i>
              </span>
            </div>
            <div class="input-group">
              <button type="button" @click="register()" class="btn mt-2 btn-primary w-100 btn-rounded">Sign UP</button>
            </div>
            <p class="text-center">By tapping “Sign Up” you accept our <a href="javascript:void(0);" class="text-primary font-w700">terms</a> and
              <a href="javascript:void(0);" class="text-primary font-w700">condition</a></p>
          </form>
        </div>
      </div>
    </div>
    <!-- Page Content End -->

    <!-- Footer -->
    <footer class="footer fixed">
      <!--      <div class="container">-->
      <!--        <router-link :to="{ name: 'login'}" class="btn btn-primary light btn-rounded text-primary d-block">-->
      <!--          Login-->
      <!--        </router-link>-->
      <!--      </div>-->
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>
import axios from "axios";
import {postRequest, getRequest} from "/public/js/Http.js"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Register",

  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_confirmation: null,
      phone_number: null,
      isInputTouched: false,
      error: null,
      success: null,
      data: []
      // isEmailInputTouched: false,
    }
  },
  computed: {
    isNumberValid() {
      return /^\d+$/.test(this.phone_number); // Checks if the input contains only digits
    },
    // isEmailValid() {
    //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
    // }
  },
  created() {
    if (navigator.onLine) {
      this.sendOfflineData();
    }
  },
  methods: {
    async register() {
      console.log(process.env.VUE_APP_API_URL);
      if (!this.first_name) {
        this.error = "First Name is Required.";
        return;
      }
      if (!this.last_name) {
        this.error = "Last Name is Required.";
        return;
      }
      if (!this.email) {
        this.error = "Email is Required.";
        return;
      }
      if (!this.password) {
        this.error = "Password is Required.";
        return;
      }
      if (!this.password_confirmation) {
        this.error = "Confirm Password is Required.";
        return;
      }
      if (this.password !== this.password_confirmation) {
        this.error = "Password is mismatch.";
        return;
      }
      var formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone", this.phone_number);
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      if (navigator.onLine) {
        try {
          await postRequest("api/register", formData)
              .then(response => {
                console.log("Success: ", response.data);
                if (response.data.status === 201) {
                  this.resetForm();
                  return this.success = response.data.message;
                } else {
                  return this.error = response.data.message;
                }
              })
              .catch(error => {
                return this.error = error.message;
              });
        } catch (e) {
          console.log(e)
        }
      } else {
        this.data = {
          "first_name" : this.first_name,
          "last_name" : this.last_name,
          "email" : this.email,
          "phone" : this.phone_number,
          "password" : this.password,
          "password_confirmation" : this.password_confirmation,
        }
        await this.offlineData(this.data);
      }
    },
    async offlineData(offlinePostData) {
      try {
        await localStorage.setItem('pendingData', JSON.stringify(offlinePostData));
      } catch (e) {
        console.log(e);
      }
    },
    async sendOfflineData() {
      try {
        const pendingData =  JSON.parse(localStorage.getItem('pendingData'));
        if (pendingData) {
          await postRequest("api/register", pendingData)
              .then((response) => {
                console.log("Success: ", response);
              })
              .catch(error => {
                console.log("Error: ", error);
              });
          await localStorage.removeItem('pendingData');
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetForm() {
      this.first_name = null;
      this.last_name = null;
      this.password = null;
      this.email = null;
      this.phone_number = null;
      this.password_confirmation = null;
    }
  }
}
</script>

<style scoped>

</style>
