<template>
  <footer class="footer fixed">
    <div class="container">
      <router-link :to="{ name: 'register'}" class="btn btn-primary light btn-rounded text-primary d-block">
        Create account
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
