import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Authentication/Login.vue'
import ForgotPassword from "@/components/Authentication/ForgotPassword";
import Register from "@/components/Authentication/Register";
import OtpConfirm from "@/components/Authentication/OtpConfirm";
import ApplyForm from "@/views/ApplyForm";
import JobDetail from "@/components/JobDetail";
import Index from "@/views/Index";
import Profile from "@/components/Driver/Profile";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/otp-confirm',
    name: 'otp-confirm',
    component: OtpConfirm
  },
  {
    path: '/apply-form',
    name: 'apply-form',
    component: ApplyForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/job-detail',
    name: 'job-detail',
    component: JobDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  let isLoggedIn = localStorage.getItem('token');
  // Check if the route requires authentication
  // If the user is logged in, allow navigation
  if (to.path === '/login' && isLoggedIn) {
    console.log(to.path)
    next('/');
  } else if (to.meta.requiresAuth && !isLoggedIn) {
    // If the user is not logged in, redirect to the login page
    next('/login');
  } else {
    // If the route does not require authentication, allow navigation
    next();
  }
});
