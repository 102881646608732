import { createApp } from 'vue'
// import  Vue  from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import '/public/js/jquery.js'
// import '/public/js/apexcharts.js'
// import './assets/main.css'
import './assets/vendor/swiper/swiper-bundle.min.css'
import './assets/css/style.css'
// import './assets/vendor/apexchart/apexchart.js'
import './assets/vendor/peity/jquery.peity.min.js'

import './assets/vendor/bootstrap-tagsinput/bootstrap-tagsinput.css'

/// js Import  ////
import '/src/assets/js/allJsFiles.js'

import './assets/vendor/swiper/swiper-bundle.min.js'
import './assets/vendor/imageuplodify/imageuploadify.min.js'
// import './assets/js/dashboard/dashboard-1.js'
import './assets/vendor/wow/dist/wow.min.js'
import store from "@/store/store";

createApp(App).use(router).use(store).mount('#app')

