<template>
  <div class="page-wraper">

    <!-- Preloader -->
    <div id="preloader">
      <div class="spinner"></div>
    </div>
    <!-- Preloader end-->

    <!-- Page Content -->
    <div class="page-content">

      <!-- Banner -->
      <div class="banner-wrapper shape-1">
        <div class="container inner-wrapper">
          <h2 class="dz-title">Forgot Password</h2>
          <p class="mb-0">Please enter your number</p>
        </div>
      </div>
      <!-- Banner End -->
      <div class="container">
        <div class="otp-area">
          <form>
            <div class="input-group">
              <input type="number" placeholder="Enter Your Number" class="form-control">
            </div>
            <div class="input-group">
              <router-link :to="{ name: 'otp-confirm'}" class="btn mt-2 btn-primary w-100 btn-rounded">
                Send OTP
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Page Content End -->

    <!-- Footer -->
    <Footer></Footer>
    <!-- Footer End -->
  </div>
</template>

<script>
import Footer from "@/components/Authentication/Footer";
export default {
  name: "ForgotPassword"
}
</script>

<style scoped>

</style>
