import axios from "axios";

export function postRequest(endPoint, data) {
    return axios.post(process.env.VUE_APP_API_URL + endPoint, data, {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
    })
}

export function getRequest(endPoint) {
    console.log(process.env.VUE_APP_API_URL + endPoint)
    return axios.get(process.env.VUE_APP_API_URL + endPoint, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token') ?? null,
            "Accept": "application/json",
        }
    })
}
