<template>
  <div class="page-wraper">

    <!-- Preloader -->
    <div id="preloader">
      <div class="spinner"></div>
    </div>
    <!-- Preloader end-->

    <!-- Page Content -->
    <div class="page-content">

      <!-- Banner -->
      <div class="banner-wrapper shape-1">
        <div class="container inner-wrapper">
          <h2 class="dz-title">Sign In</h2>
          <p class="mb-0">Please sign in to your registered account</p>
          <span v-if="error" style="color: red;">{{ error }}</span>
          <span v-if="success" style="color: darkseagreen;">{{ success }}</span>
        </div>
      </div>
      <!-- Banner End -->

      <div class="container">
        <div class="account-area">
          <form>
            <div class="input-group">
              <input type="email" placeholder="User Name" class="form-control" v-model="email">
            </div>
            <div class="input-group">
              <input type="password" placeholder="Password" id="dz-password" class="form-control be-0"
                     v-model="password">
              <span class="input-group-text show-pass">
							<i class="fa fa-eye-slash"></i>
							<i class="fa fa-eye"></i>
						</span>
            </div>
            <router-link :to="{ name: 'forgot-password'}" class="btn-link d-block text-center">
              Forgot your password?
            </router-link>
            <button
                class="btn mt-2 btn-primary w-100 btn-rounded"
                type="button"
                @click="login()"
            >
              Login
            </button>
          </form>
          <div class="text-center p-tb20">
            <span class="saprate">Or sign in with</span>
          </div>
          <div class="social-btn-group text-center">
            <a href="https://www.google.com/" target="_blank" class="social-btn"><img
                src="/assets/images/social/google.png" alt="socila-image"></a>
            <a href="https://www.facebook.com/" target="_blank" class="social-btn ms-3"><img
                src="/assets/images/social/facebook.png" alt="social-image"></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content End -->

    <!-- Footer -->
    <Footer></Footer>
    <!-- Footer End -->
  </div>
</template>

<script>
import Footer from "@/components/Authentication/Footer";
import {postRequest, getRequest} from "/public/js/Http";
import store from "@/store/store";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  components: {Footer},
  data() {
    return {
      email: null,
      password: null,
      userData: null,
      error: null,
      success: null
    }
  },
  // created() {
  //   if (window.location.search.substring(1)) {
  //     let param = window.location.search.substring(1);
  //     let val = param.split("=");
  //     if (val) {
  //       axios.get(process.env.VUE_APP_API_URL + 'api/email/verification?' + val[1])
  //     }
  //   }
  // },
  methods: {
    async login() {
      if (!this.email) {
        return this.error = "Email is Required."
      }
      if (!this.password) {
        return this.error = "Password is Required."
      }

      var formData = new FormData();
      formData.append('email', this.email);
      formData.append('password', this.password);
      try {
        if (navigator.onLine) {
          await postRequest("api/login", formData)
              .then(response => {
                if (response.data.status === 202) {
                  localStorage.setItem('token', response.data.data.token)
                  localStorage.setItem('email', response.data.data.user.email)
                  // this.$store.dispatch('loginAction', response.data.data)
                  this.$store.dispatch('user', {'email': this.email})
                  // console.log(this.$store.getters.getToken);
                  // console.log(this.$store.state.isLoggedIn)
                  window.location.href = '/';
                } else {
                  this.error = response.data.message
                }
              })
              .catch(e => {
                console.log('in catch')
                console.log(e)
              })
        } else {
          const email = localStorage.getItem('email');
          if (this.email === email) {
            window.location.href = '/';
          } else {
            this.error = "One time you will need to be online."
          }
        }
      } catch (e) {
        console.log('catch 2')
        return this.e = e.message;
      }
    }
  }
}
</script>
