import Vue from 'vue';
import {createApp} from 'vue'
// import {createStore} from 'vuex';
import Vuex from 'vuex';
import App from "@/App";

// Vue.use(Vuex);

const state = {
    user: null
}

export default new Vuex.Store({
    state/*: {
        token: null,
        userInfo: null,
        isLoggedIn: false
    }*/,
    getters: {
        getToken(state) {
            return state.token;
        },
        user(state) {
            return state.user;
        },
    },
    actions: {
        loginAction(context, token) {
            context.commit('setToken', token);
        },
        user(context, user) {
            context.commit('user', user);
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            state.isLoggedIn = true
        },
        user(state, user) {
            state.user = user;
        }
    }
});

// const app = createApp(App)
// app.use(store)
// export default store;
